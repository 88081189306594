/*
 * 餐厅职位接口
 * */
import request from '@/utils/request'

/*
 * 获取职位列表
 * */
export function getPositionList() {
  return request({
    url: '/work/position/lists',
    method: 'get',
  })
}

/*
 * 保存（添加修改）职位
 * */
export function savePosition(parameter) {
  return request({
    url: '/work/position/get',
    method: 'post',
    data: parameter,
  })
}

/*
 * 删除职位
 * */
export function deletePosition(parameter) {
  return request({
    url: '/work/position/delete',
    method: 'get',
    params: parameter,
  })
}
